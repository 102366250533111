<template>
  <div id="apipe-cloud-course-page">
    <top-nav-new />

    <div class="apipe-cloud-course-container-made">
      <div class="top-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h2>优制云MES</h2>
          <div class="tit">
            解决工厂生产过程的"黑匣子"问题，实现生产过程透明化、可视化、可控化。
          </div>
          <div class="tit">实现工厂生产全面数字化、智能化和自动化，提高生产效率和质量，降低生产成本。</div>
          <div class="exp" @click="handleTest">立即体验</div>
        </div>
      </div>
      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="text-align: left">
          <h4>优制云MES</h4>
          <div class="tit">解决工厂生产过程的"黑匣子"问题，</div>
          <div class="tit">实现生产过程透明化、可视化、可控化。</div>
          <div class="tit">实现工厂生产全面数字化、智能化和自动化，</div>
          <div class="tit">提高生产效率和质量，降低生产成本。</div>
          <!-- <span class="exp" @click="handleTest">立即体验</span> -->
        </div>
      </div>

      <div class="customeValue">
        <h4 class="pro-tit">客户价值</h4>
        <h6 class="pro-tits">
          提高生产效率和质量，优化资源配置，降低成本，帮助企业更好地实现数字化和智能化制造的目标。
        </h6>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/product.png" alt="" />
                <h6>生产现场跟踪</h6>
                <p>
                  管理生产过程中的各种数据，实时了解订单完成情况、工序的进度、人员的分配等
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/plan.png" alt="" />
                <h6>生产计划协调</h6>
                <p>
                  MES系统结合ERP系统，可以实现生产规划和实际生产的相互协调
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/store.png" alt="" />
                <h6>仓库管理</h6>
                <p>
                  帮助管理仓库，实现实物的实时统一，以及仓库的存放地点和管理
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/equ.png" alt="" />
                <h6>设备管理</h6>
                <p>
                  通过系统接口连接设备，自动获取数据，实现了对设备运行的实时监控，减少了人员记录及管理的工作量
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/protect.png" alt="" />
                <h6>质量管理</h6>
                <p>
                  管理从上料到成品整个生产过程中的各种数据，实现IQC、FAI、IPQC、FQC、OQC实时数据呈现，及时对不良品进行分析处理，提升质量
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/file.png" alt="" />
                <h6>文档管理</h6>
                <p>
                  MES系统可以自动获取和推送所需的图纸、通知、工艺反馈文件、操作指导书等文件，实现自动化管理，减少人工劳动，提升效率
                </p>
              </div>
            </div>
            <div class="col">
              
              <div class="p-3">
                <img src="@/assets/newSite/product/kpi.png" alt="" />
                <h6>绩效管理/统计分析</h6>
                <p>
                 
对生产过程中产生的丰富数据依据MES强大的自动分析功能，快速形成各式报表及看板，为管理者提供参考数据和决策支持
                </p>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/product/combin.png" alt="" />
                <h6>集成其他系统</h6>
                <p>
                  MES系统可与其他系统进行集成，如ERP、SCM、CRM等，实现数据的共享和流程的优化
                </p>
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <div class="sys container">
        <h4 class="pro-tit">系统架构</h4>
        <img src="@/assets/newSite/made/sys.png" style="width: 100%" alt="" />
      </div>

      <div class="pc-function d-none d-lg-block">
        <div class="container">
          <h4 class="pro-tit">
            功能应用
            <span :class="['opt', 'el-icon-arrow-left',activeName!=0?'activ':'dis']" @click="handleLeft"></span>
            <span :class="['opt', 'el-icon-arrow-right',activeName!=8?'activ':'dis']" @click="handleRight"></span>
          </h4>
          <div class="main-cot">
            <el-tabs
              class="myTab"
              v-model="activeName"
              @tab-click="handleClick"
            >
              <el-tab-pane label="首页看板" name="0">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px;">
                    <span class="sp">首页看板</span>
                    <p class="w-cot">
                      实时展示生产质量、进度、交付的数据信息，包括原料入库数量、已入库数量、IQC检验数据、计划加工数量及已加工数量、计划入库产品数量及已入库数量，以及各个车间、产线生产实时进度展示图
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/made/s1.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="标签管理" name="1">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px;text-align: left;">
                    <span class="sp">标签管理</span>
                    <p class="w-cot">
                      对工厂里的原料、库位、库架、单据等各种标签按照设计模板自动生成条码，满足条码多场景应用，还可以根据客户需要打印产品、物料、客户、包装、不良等各种条码，方便管理。
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/made/s2.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="订单管理" name="2">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">订单管理</span>
                    <p class="w-cot">
                      对生产订单进行管理，包括订单编码、数量、名称、计划开始时间与结束时间，根据生产排产加工的生产进度，实时管理订单情况。
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/made/s3.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="工艺管理" name="3">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">工艺管理</span>
                    <p class="w-cot">
                      采用可视化工作流引擎，以拖拉拽方式创建工单或者产品的工艺路线，快速生成，方便、快捷、直观，所见所得。
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/made/s4.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="ESOP管理" name="4">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">ESOP管理</span>
                    <p class="w-cot">
                      无纸化电子看板、一键下发，省时省力，提高效率，支持Word、Excel、PPT、PDF、视频等文件，在线视频培训，而且员工可以随时阅读培训视频支持多种视频格式(MP4等)
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/made/s5.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="质量管理" name="5">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">质量管理</span>
                    <p class="w-cot">
                      通过MES系统对产品质量进行管理，包括检验、上报、维修、改进，确保在制造过程中产品符合设定的质量标准和要求，通过实时数据采集、分析和反馈，帮助企业提高产品质量、降低生产成本和提升客户满意度。
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/made/s6.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="设备管理" name="6">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">设备管理</span>
                    <p class="w-cot">
                      通过对生产设备信息的收集、储存、加工、分析与控制，提高设备使用效率、提高维护维修效率、降低设备维护维修以及管理成本，优化报修响应、故障预测、设备更替和维护的管理系统。
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/made/s7.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="追溯管理" name="7">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">追溯管理</span>
                    <p class="w-cot">
                      通过原料信息查询使用该原料的工单、产品以及该产品经过的工序、出货去向等信息的正向追溯，也可以通过出货单、箱号、客户序列号，从后往前查询该产品所经工序、人、机、料、法、测等信息的反向追溯；对正向或反向追溯的情况生成报表，并可对报告进行推送，方便查阅。
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/made/s8.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="看板管理" name="8">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">看板管理</span>
                    <p class="w-cot">
                      MES看板可以以图形化和数字化的方式进行实时生产数据展示，如产量、质量指标、设备状态等，使生产情况一目了然。为管理层提供决策支持，帮助优化生产流程和制定改进策略。
                    </p>
                    <button type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/made/s9.png"
                      style="height: 330px"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>

      <div class="mobile-function d-block d-lg-none">
        <div class="container">
          <h4 class="pro-tit">
            功能应用
          </h4>
          <div class="main-cot">
            <el-tabs
              class="myTab"
              v-model="activeName"
              @tab-click="handleClick"
            >
              <el-tab-pane label="首页看板" name="0">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px;">
                    <span class="sp">首页看板</span>
                    <p class="w-cot">
                      实时展示生产质量、进度、交付的数据信息，包括原料入库数量、已入库数量、IQC检验数据、计划加工数量及已加工数量、计划入库产品数量及已入库数量，以及各个车间、产线生产实时进度展示图
                    </p>
                    <p style="text-align:center;">
                      <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                  </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/made/s1.png"
                      style="width: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="标签管理" name="1">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px;text-align: left;">
                    <span class="sp">标签管理</span>
                    <p class="w-cot">
                      对工厂里的原料、库位、库架、单据等各种标签按照设计模板自动生成条码，满足条码多场景应用，还可以根据客户需要打印产品、物料、客户、包装、不良等各种条码，方便管理。
                    </p>
                    <p style="text-align:center;">
                      <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                  </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/made/s2.png"
                      style="width: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="订单管理" name="2">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">订单管理</span>
                    <p class="w-cot">
                      对生产订单进行管理，包括订单编码、数量、名称、计划开始时间与结束时间，根据生产排产加工的生产进度，实时管理订单情况。
                    </p>
                    <p style="text-align:center;">
                      <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                  </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/made/s3.png"
                      style="width: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="工艺管理" name="3">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">工艺管理</span>
                    <p class="w-cot">
                      采用可视化工作流引擎，以拖拉拽方式创建工单或者产品的工艺路线，快速生成，方便、快捷、直观，所见所得。
                    </p>
                    <p style="text-align:center;">
                      <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                  </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/made/s4.png"
                      style="width: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="ESOP管理" name="4">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">ESOP管理</span>
                    <p class="w-cot">
                      无纸化电子看板、一键下发，省时省力，提高效率，支持Word、Excel、PPT、PDF、视频等文件，在线视频培训，而且员工可以随时阅读培训视频支持多种视频格式(MP4等)
                    </p>
                    <p style="text-align:center;">
                      <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                  </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/made/s5.png"
                      style="width: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="质量管理" name="5">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">质量管理</span>
                    <p class="w-cot">
                      通过MES系统对产品质量进行管理，包括检验、上报、维修、改进，确保在制造过程中产品符合设定的质量标准和要求，通过实时数据采集、分析和反馈，帮助企业提高产品质量、降低生产成本和提升客户满意度。
                    </p>
                    <p style="text-align:center;">
                      <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                  </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/made/s6.png"
                      style="width: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="设备管理" name="6">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">设备管理</span>
                    <p class="w-cot">
                      通过对生产设备信息的收集、储存、加工、分析与控制，提高设备使用效率、提高维护维修效率、降低设备维护维修以及管理成本，优化报修响应、故障预测、设备更替和维护的管理系统。
                    </p>
                    <p style="text-align:center;">
                      <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                  </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/made/s7.png"
                      style="width: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="追溯管理" name="7">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">追溯管理</span>
                    <p class="w-cot">
                      通过原料信息查询使用该原料的工单、产品以及该产品经过的工序、出货去向等信息的正向追溯，也可以通过出货单、箱号、客户序列号，从后往前查询该产品所经工序、人、机、料、法、测等信息的反向追溯；对正向或反向追溯的情况生成报表，并可对报告进行推送，方便查阅。
                    </p>
                    <p style="text-align:center;">
                      <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                  </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/made/s8.png"
                      style="width: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="看板管理" name="8">
                <div class="wrap">
                  <div style="flex: 1; padding: 10px">
                    <span class="sp">看板管理</span>
                    <p class="w-cot">
                      MES看板可以以图形化和数字化的方式进行实时生产数据展示，如产量、质量指标、设备状态等，使生产情况一目了然。为管理层提供决策支持，帮助优化生产流程和制定改进策略。
                    </p>
                    <p style="text-align:center;">
                      <!-- <button @click="handleTest" type="button" class="btn btn-primary btn-sm">
                      立即体验
                    </button> -->
                  </p>
                  </div>
                  <div style="flex: 1; padding: 10px">
                    <img
                      src="@/assets/newSite/made/s9.png"
                      style="width: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>

 

      <div class="scene">
        <h4 class="pro-tit">适用场景</h4>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/made/a1.png" alt="" />
                <h6>机械加工</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/made/a2.png" alt="" />
                <h6>电子组装</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/made/a3.png" alt="" />
                <h6>五金机加</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/made/a4.png" alt="" />
                <h6>注塑行业</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/made/a5.png" alt="" />
                <h6>塑胶管道</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/made/a6.png" alt="" />
                <h6>汽车加工</h6>
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <right-nav-new class="d-none d-lg-block" />
    </div>

    <NewFooter />
    <MobileFooter class="d-block d-lg-none" />
  </div>
</template>
    
    <script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperCore, { Autoplay } from "swiper";
SwiperCore.use([Autoplay]);

export default {
  name: "hardware",
  data() {
    return {
      activeName: "1",

      tabType: 0,
      tab: 0,
      tabUl: 0,
      swiperOptionsCourse: {
        // height: 100,
      },
      swiperOptionsCoursePanel: {
        // height: 100,
      },
      active: "#home",
      scrollIntoViewOptions: {
        block: "start",
        behavior: "smooth",
      },
      distance_team: 0,
      distance_contact: 0,
      distance_join: 0,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    mySwiper() {
      return this.$refs.swiperCourse.$swiper;
    },
    coursePanel() {
      return this.$refs.coursePanel.$swiper;
    },
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleTest(){
     window.open('https://apipecloud.veiban.com/login')
    },
    handleLeft() {
      const arrs = ["0", "1", "2", "3", "4", "5", "6",'7','8'];
      const current = this.activeName;
      let posIdx = arrs.findIndex((n) => n == current);
      if (posIdx == 0) return;
      this.activeName = arrs[posIdx - 1];
    },
    handleRight() {
      const arrs = ["0", "1", "2", "3", "4", "5", "6",'7','8'];
      const current = this.activeName;
      let posIdx = arrs.findIndex((n) => n == current);
      if (current == arrs.length - 1) return;
      this.activeName = arrs[posIdx + 1];
    },
    switchTab(index) {
      this.tabType = index;
      let e = $(".panel-nav").find("div");
      e = e[index];
      $(e).siblings().removeClass("active");
      $(e).addClass("active");
      this.coursePanel.slideTo(index, 1000, false);
    },
    switchType(index) {
      let e = $(".banner-nav").find("li")[index];
      $(e).siblings().removeClass("active");
      $(e).addClass("active");
      this.mySwiper.slideTo(index, 1000, false);
    },
    windowHeight() {
      var de = document.documentElement;
      console.log(de);
      return (
        self.innerHeight ||
        (de && de.offsetHeight) ||
        document.body.offsetHeight
      );
    },
    toTarget(target) {
      this.active = target;
      const toElement = document.querySelector(target);
      toElement.scrollIntoView(this.scrollIntoViewOptions);
    },
    onScroll() {
      let scrolled = Math.abs(
        document.getElementById("appp").getBoundingClientRect().top
      );
      if (scrolled < this.distance_team) {
        this.active = "#home";
      } else if (
        scrolled >= this.distance_team &&
        scrolled < this.distance_contact
      ) {
        this.active = "#team";
      } else if (
        scrolled >= this.distance_contact &&
        scrolled < this.distance_join
      ) {
        this.active = "#join";
      }
    },
    tabChange(e) {
      let tabid = e.target.id;
      if (tabid === this.tabUl || !tabid) return;
      var ul = document.querySelector(".ullist");
      console.log(tabid);
      ul.children[tabid].classList.remove("list2");
      ul.children[tabid].classList.add("list1");
      ul.children[this.tabUl].classList.remove("list1");
      ul.children[this.tabUl].classList.add("list2");
      this.tabUl = tabid;
      this.tab = tabid;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll, false);
  },
};
</script>
    
    <style lang="less"  >
.apipe-cloud-course-container-made {
  .top-banner {
    height: 600px;
    background-image: url("../../assets/newSite/pro.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 16px;
      }
      .exp {
        margin-top: 2rem;
        font-size: 14px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        padding: 2px 0;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .mobile-banner {
    height: 200px;
    background-image: url("../../assets/newSite/pro.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    .container {
      color: white;
      padding: 1rem 1rem 1rem 1.2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 12px;
      }
      .exp {
        margin-top: 1rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }

  .customeValue {
    margin: 50px 0;
    .pro-tits {
      color: #666;
      margin-bottom: 30px;
      padding: 0 10px;
    }
    .col {
      .p-3 {
        // border: 1px solid red;
        min-height: 200px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }

    img {
      width: 50px;
      height: 50px;
    }
    p {
      color: #888;
      font-size: 13px;
    }
    h6 {
      margin-top: 1rem;
    }
  }

  .sys {
    margin-bottom: 30px;
  }

  .pc-function {
    background: #f5f6f7;
    padding: 30px 0;
    margin-bottom: 30px;
    .container {
      .pro-tit {
        position: relative;
        .opt {
          display: inline-block;
          height: 50px;
          width: 50px;
          line-height: 50px;
          border-radius: 50%;
          text-align: center;
          border: 1px solid #ccc;
          &:hover {
            cursor: pointer;
          }
        }
        .el-icon-arrow-left {
          position: absolute;
          left: 0;
          bottom: -56px;
          z-index: 99;
        }
        .el-icon-arrow-right {
          position: absolute;
          right: 0;
          bottom: -56px;
          z-index: 99;
        }

        .activ {
          border: 1px solid rgba(20, 132, 255, 1);
          color: rgba(20, 132, 255, 1);
        }
        .dis {
          cursor: not-allowed !important;
        }

      }
      .main-cot {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .myTab {
          .el-tabs__nav-scroll .el-tabs__nav {
            width: 100% !important;

            .el-tabs__active-bar {
              background-color: transparent !important;
            }
            .el-tabs__item {
              padding: 0 30px;
            }
          }
          .el-tabs__nav-wrap::after {
            height: 0 !important;
          }

          .wrap {
            height: 400px;
            display: flex;
            padding: 30px;
            text-align: left;
            // border: 1px solid red;
            width: 1200px;
            .sp {
              font-size: 16px;
              color: #333333;
              // font-weight: 700;
            }
            .w-cot {
              font-size: 14px;
              color: #666666;
              margin-top: 10px;
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
  .mobile-function {
    background: #f5f6f7;
    padding: 30px 0;
    margin-bottom: 30px;
    .container {
      .pro-tit {
        position: relative;
        .opt {
          display: inline-block;
          height: 50px;
          width: 50px;
          line-height: 50px;
          border-radius: 50%;
          text-align: center;
          border: 1px solid #ccc;
          &:hover {
            cursor: pointer;
          }
        }
        .el-icon-arrow-left {
          position: absolute;
          left: 0;
          bottom: -56px;
          z-index: 99;
        }
        .el-icon-arrow-right {
          position: absolute;
          right: 0;
          bottom: -56px;
          z-index: 99;
        }

        .activ {
          border: 1px solid rgba(20, 132, 255, 1);
          color: rgba(20, 132, 255, 1);
        }
        .dis {
          cursor: not-allowed !important;
        }

      }
      .main-cot {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .myTab {
          width: 100%;
          .el-tabs__nav-scroll .el-tabs__nav {
            // width: 100% !important;

            .el-tabs__active-bar {
              background-color: transparent !important;
            }
            .el-tabs__item {
              padding: 0 30px;
            }
          }
          .el-tabs__nav-wrap::after {
            height: 0 !important;
          }

          .wrap { 
            text-align: left;
            // border: 1px solid red;
            // width: 1200px;
            .sp {
              font-size: 16px;
              color: #333333;
              // font-weight: 700;
            }
            .w-cot {
              font-size: 14px;
              color: #666666;
              margin-top: 10px;
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
  .scene {
    margin: 50px 0;
    .pro-tit {
      margin-bottom: 30px;
    }
    .col {
      .p-3 {
        min-height: 200px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }
    img {
      width: 100%;
      height: auto;
    }
    h6 {
      margin-top: 1rem;
    }
  }
}

.content-title {
  width: 100%;
  background-color: #f3f8ff;
  height: 60px;
}
.content-title ul {
  max-width: 1240px;
  margin: 0 auto;
}
.content-title ul li {
  float: left;
  width: 64px;
  height: 60px;
  opacity: 1;
  font-size: 16px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: right;
  color: #657687;
  line-height: 24px;
  margin-right: 126px;
}
//   #appp {
//     /* 关键代码，需要给容器添加高度 */
//     position: relative;
//     width: 100%;
//   }
.content-title ul li a {
  display: block;
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #808d9f;
  text-decoration: none;
}
.content-title ul li a:hover,
.content-title ul li a:active {
  color: #1584ff;
}
.content-title ul li a.active {
  color: #1584ff;
  border-bottom: 1px solid blue;
}
.Pain_pointbox {
  position: relative;
}
.Pain_pointbox_img {
  width: 100%;
  height: 100%;
}
#home,
#join,
#team {
  //   max-width: 1282px;
  width: 100%;
  margin: 0 auto;
  //   height: 200px;
  //   color: #ffff;
  font-size: 30px;
  text-align: center;
  //   border: 1px solid black;
  //   line-height: 200px;
  padding-top: 60px;
}
.Industry_background_box {
  position: relative;
  height: 650px;
  padding-left: 17%;
}
.Industry_background_top {
  height: 126px;
  max-width: 1281px;
  opacity: 1;
}
.Industry_background_top_p1 {
  height: 34px;
  opacity: 1;
  font-size: 34px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  color: #2c323b;
  line-height: 51px;
  margin: 16px 0px 50px 0px;
}
.Industry_background_top_p2 {
  height: 61px;
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: center;
  color: #7d7d7d;
  line-height: 24px;
}
.problem {
  max-width: 1281px;
  margin-top: 120px;
}
#join {
  background: #f3f8ff;
  //   padding-bottom: 70px;
  height: 717px;
}
#join .title {
  height: 14px;
  opacity: 1;
  font-size: 1vh;
  font-family: SourceHanSansCN, SourceHanSansCN-Normal;
  font-weight: Normal;
  text-align: center;
  color: #657687;
  padding-top: 5px;
}
#join .title2 {
  font-size: 4vh;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  // text-align: justify;
  color: #262c33;
}

.clickbtn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 37%;
  top: 55%;
  border-radius: 30px;
  width: 9vw;
  height: 6vh;
  font-size: 2vh;
  border: 1px solid #1584ff;
  background-color: #1584ff;
  color: white;
  cursor: pointer//鼠标悬浮变 手;
}
.problem_box {
  display: flex;
  max-width: 1281px;
  // flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 60px;
}
.problem_box1 {
  width: 25%;
  height: 298px;
  opacity: 1;
  background: #fbfcfd;
  border-radius: 10px;
  margin: 5px;
  position: relative;
}
.problem_box1_img {
  margin: 30px 0px 20px 0px;
}
.problem_box1_title {
  height: 24px;
  opacity: 1;
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: center;
  color: #3a3a3a;
  line-height: 36px;
  margin-bottom: 20px;
}
.problem_box1_content {
  height: 81px;
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: justify;
  color: #3a3a3a;
  line-height: 27px;
  margin: 22px;
}
.problem_box1:hover {
  background-color: white;
  box-shadow: 0px 4px 18px 0px #daebff;
  border-bottom: 15px solid #1584ff;
}
#team {
  position: relative;
}
.Mechanics_5_img {
  position: relative;
}
.introduce {
  position: absolute;
  top: 19%;
  left: 39%;
}
.introduce .p1 {
  height: 34px;
  opacity: 1;
  font-size: 34px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 51px;
}
.introduce .p2 {
  height: 20px;
  opacity: 1;
  font-size: 20px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 30px;
  margin-top: 27px;
}
.introduce2 {
  position: absolute;
  top: 35%;
  left: 20%;
}
.introduce2_box {
  display: flex;
}
.introduce2_box_content {
  display: flex;
  width: 30vw;
  height: 195px;
  opacity: 1;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
}
.introduce2_box_content1 {
  display: flex;
  width: 30vw;
  height: 195px;
  opacity: 1;
  background: #ffffff;
  border-radius: 10px;
  margin-left: 19px;
  margin-bottom: 20px;
}
.introduce2_box_content_img {
  margin-right: 25px;
  margin-top: 47px;
  margin-left: 50px;
}
.introduce2_box_content1_img {
  margin-right: 25px;
  margin-top: 47px;
  margin-left: 50px;
}
.introduce2_box_content_title {
  text-align: left;
  margin-right: 59px;
}
.introduce2_box_content_title .p1 {
  height: 24px;
  opacity: 1;
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 36px;
  padding-top: 40px;
}
.introduce2_box_content_title .p2 {
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: justify;
  color: #333333;
  padding-top: 45px;
}
.introduce2_box_content1_title {
  text-align: left;
  margin-right: 59px;
}
.introduce2_box_content1_title .p1 {
  height: 24px;
  opacity: 1;
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 36px;
  padding-top: 40px;
}
.introduce2_box_content1_title .p2 {
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: justify;
  color: #333333;
  padding-top: 45px;
}
.function {
  height: 51px;
  opacity: 1;
  font-size: 34px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  color: #262c33;
  line-height: 51px;
  margin-top: 60px;
}
.function_content {
  display: flex;
  margin: 0% 15%;
  //   border: 1px solid #1584ff;
}
.ulli {
  padding-left: 8%;
  height: 50%;
  padding-top: 7%;
}
.function_content_box {
  display: flex;
  width: 100%;
  margin-left: 10%;
}
.function_content_box_content {
  text-align: left;
  padding-top: 8%;
  width: 20vw;
}
.function_content_box_content .p1 {
  height: 21px;
  opacity: 1;
  font-size: 14px;
  font-family: SourceHanSansCN, SourceHanSansCN-Normal;
  font-weight: Normal;
  text-align: left;
  color: #657687;
  line-height: 21px;
}
.function_content_box_content .p2 {
  height: 36px;
  opacity: 1;
  font-size: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: justify;
  color: #262c33;
  line-height: 36px;
  margin-bottom: 20px;
  margin-top: 15px;
}
.function_content_box_content .p3,
.function_content_box_content .p4,
.function_content_box_content .p5,
.function_content_box_content .p6 {
  //   height: 33px;
  opacity: 1;
  font-size: 18px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 26px;
  margin-top: 20px;
}
 
</style>
    